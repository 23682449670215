import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import styled from "styled-components";
import lottie from 'lottie-web';
import applicationTrackerAnimation from '../assets/lottieanimations/application_tracker_demo';
import collegeListAnimation from '../assets/lottieanimations/college_list_demo';
import collegeDiscoveryAnimation from '../assets/lottieanimations/college_discovery_demo';
import { loadStripe } from "@stripe/stripe-js";
import { FaCheckCircle, FaQuestionCircle } from "react-icons/fa";
import axios from "axios";
import ReactFlow, {
  Handle,
  Background,
  ReactFlowProvider,
  getMarkerEnd,
} from 'reactflow';
import 'reactflow/dist/style.css';

const StyledCard = styled(Card)`
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  max-width: 220px; /* Adjusted max-width to make rectangles smaller */
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
`;

const QuestionCard = styled(StyledCard)`
  background-color: ${({ isSubmitted }) => (isSubmitted ? "#4CAF50" : "#ffffff")};
  transition: background-color 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isSubmitted }) => (isSubmitted ? "center" : "flex-start")};
  padding: 0;
  max-width: 400px; /* Set a max width to control the size */
  width: 100%; /* Ensure full width */
`;

const StyledTextArea = styled(Form.Control)`
  border-radius: 8px;
  border: 1px solid #ced4da;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  resize: vertical;
  min-height: 120px;
  margin-bottom: 1rem;
  width: 100%;
`;

const SubmitButton = styled(Button)`
  background-color: #6e8efb;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: bold;
  transition: all 0.3s ease;
  width: 100%;
  
  &:hover {
    background-color: #5a7ae2;
    transform: translateY(-2px);
  }
`;

const GuestQuestionPageWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const GuestQuestionPage = () => {
  const [newQuestion, setNewQuestion] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const featuresSectionRef = useRef(null);

  const applicationAnimationContainer = useRef(null);
  const collegeListContainer = useRef(null);
  const collegeDiscoveryContainer = useRef(null);

  useEffect(() => {
    document.title = "Ask Your Question | YouElite";

    let animApp;
    if (applicationAnimationContainer.current) {
      animApp = lottie.loadAnimation({
        container: applicationAnimationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: applicationTrackerAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
    }

    let animList;
    if (collegeListContainer.current) {
      animList = lottie.loadAnimation({
        container: collegeListContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: collegeListAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
    }

    let animDiscovery;
    if (collegeDiscoveryContainer.current) {
      animDiscovery = lottie.loadAnimation({
        container: collegeDiscoveryContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: collegeDiscoveryAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
    }

    return () => {
      animApp && animApp.destroy();
      animList && animList.destroy();
      animDiscovery && animDiscovery.destroy();
    };
  }, []);

  const handleSubmitQuestion = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.student.youelite.net/api/guest-create-token-checkout-session",
        {
          email,
          question: newQuestion,
          bundle: `1-token`, 
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const sessionId = response.data.id;
      const stripe = await loadStripe("pk_live_51PuZ5305p8417GVD63PxJhMln5YoFPARrSRcB7dyPXwhbBG9Er6nCzDmnp7u0A816YSA6c6bc8jpsszvzQKvsKIM00IDKSOH5g");
      stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error("Error during checkout", error);
      alert("Error during checkout");
    } finally {
      setLoading(false);
    }
  };

  const scrollToFeatures = () => {
    if (featuresSectionRef.current) {
      featuresSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const nodes = [
    {
      id: '1',
      type: 'customNode',
      position: { x: 100, y: 100 },
      data: {
        title: 'Write question',
        description: 'Ask any college admissions question that iss on your mind!',
      },
    },
    {
      id: '2',
      type: 'customNode',
      position: { x: 350, y: 250 },
      data: {
        title: 'Submit',
        description: 'Enter your email and send your question — no login required',
      },
    },
    {
      id: '3',
      type: 'customNode',
      position: { x: 600, y: 100 },
      data: {
        title: 'Our Experts',
        description: 'Your question is reviewed by top admissions experts!',
      },
    },
    {
      id: '4',
      type: 'customNode',
      position: { x: 850, y: 250 },
      data: {
        title: 'Polished Answer',
        description: 'Receive a detailed, expert answer directly in your inbox!',
      },
    },
  ];

  const edges = [
    {
      id: 'e1-2',
      source: '1',
      target: '2',
      type: 'wiggly',
      markerEnd: 'arrowclosed',
    },
    {
      id: 'e2-3',
      source: '2',
      target: '3',
      type: 'wiggly',
      markerEnd: 'arrowclosed',
    },
    {
      id: 'e3-4',
      source: '3',
      target: '4',
      type: 'wiggly',
      markerEnd: 'arrowclosed',
    },
  ];

  const CustomNodeComponent = ({ data }) => {
    return (
      <StyledCard>
        <Handle
          type="target"
          position="top"
          style={{ opacity: 0 }} 
        />
        <Card.Body>
          <h5 style={{ fontWeight: 'bold', fontSize: '18px' }}>{data.title}</h5>
          <p style={{ color: '#000000', fontSize: '16px' }}>{data.description}</p>
        </Card.Body>
        <Handle
          type="source"
          position="bottom"
          style={{ opacity: 0 }}
        />
      </StyledCard>
    );
  };

  const nodeTypes = {
    customNode: CustomNodeComponent,
  };

  const WigglyEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    markerEnd,
  }) => {
    const edgePath = `M${sourceX},${sourceY} Q ${(sourceX + targetX) / 2},${sourceY - 100} ${targetX},${targetY}`;
    const markerEndUrl = getMarkerEnd(markerEnd);

    return (
      <>
        <path
          id={id}
          d={edgePath}
          stroke="#000"
          fill="none"
          markerEnd={markerEndUrl}
          strokeWidth={2}
          className="react-flow__edge-path"
        />
      </>
    );
  };

  const edgeTypes = {
    wiggly: WigglyEdge,
  };

  const DiagramSection = () => {
    useEffect(() => {
      const reactFlowAttribution = document.querySelector('.react-flow__attribution');
      if (reactFlowAttribution) {
        reactFlowAttribution.style.display = 'none';
      }
    }, []);
    return (
      <div style={{ height: '300px', width: '100%' }}>
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodesDraggable={false}
            nodesConnectable={false}
            elementsSelectable={false}
            zoomOnScroll={false}
            zoomOnPinch={false}
            panOnScroll={false}
            panOnDrag={false} 
            preventScrolling={false} 
            defaultCursor="default"
            fitView
          >
          </ReactFlow>
        </ReactFlowProvider>
      </div>
    );
  };
  

  return (
    <GuestQuestionPageWrapper>
      <Row className="g-0"
        style={{
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh', 
        }}
        >

        <Col
            md="7"
            lg="5"
            xl="4"
            className="col-wrapper"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                overflow: 'hidden',
            }}
        >
          <QuestionCard isSubmitted={isSubmitted}>
            {!isSubmitted ? (
              <>
                <Card.Header className="d-flex align-items-center">
                  <FaQuestionCircle className="me-2" />
                  <Card.Title as="h6" className="mb-0">Ask a New Question</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <div className="mb-3">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <StyledTextArea
                      as="textarea"
                      placeholder="Type your question here. Be precise and detailed."
                      value={newQuestion}
                      onChange={(e) => setNewQuestion(e.target.value)}
                      required
                    />
                    <SubmitButton
                      onClick={handleSubmitQuestion}
                      disabled={!newQuestion || !email || loading}
                      variant="primary"
                    >
                      {loading ? "Processing..." : "Submit for $2.99"}
                    </SubmitButton>
                    <div className="mt-2">
                        <small>
                          <strong>Stripe</strong>-secured checkout. Your data are safe with us.{' '}
                        </small>
                    </div>
                  </Form>
                </Card.Body>
              </>
            ) : (
              <div className="text-center">
                <FaCheckCircle size={48} color="white" />
                <h5 className="text-white mt-3">Question sent successfully!</h5>
              </div>
            )}
          </QuestionCard>
        </Col>

        <Col
        className="d-none d-lg-block p-5"
        style={{
            backgroundColor: "#fcfbfb",
            borderLeft: "1px solid #e0e0e0",
            height: "100vh",
            overflowY: "auto",
            display: 'flex',
            flexDirection: 'column',
        }}
        >
          <div className="header-section mt-5" style={{ textAlign: "center", marginBottom: "40px" }}>
            <h1 style={{ fontWeight: "bold", fontSize: "34px", marginBottom: "10px" }}>
            Get Expert College Admissions Advice for Only $2 No Account Needed!
            </h1>
            <p style={{ fontSize: "16px" }}>
              Why pay hundreds for a full consultant when you just need quick answers? For only $2, you can get exactly what you need—personalized, expert advice on your specific questions. No login, no lengthy process—just fast, affordable help to move forward with confidence.
            </p>
            <p style={{ fontWeight: "bold", fontSize: "16px", display:"none" }}>
              Join thousands of students who are preparing their applications. Start today to get ahead of the competition!
            </p>
          </div>

          <div className="button-section" style={{ textAlign: "center", marginBottom: "40px", display:"none" }}>
            <Button variant="primary" style={{ marginRight: "10px", padding: "10px 20px" }}>
              Get Started for Free—Take Control of Your College Journey!
            </Button>
            <Button
              variant="outline-dark"
              style={{ padding: "10px 20px" }}
              onClick={scrollToFeatures}
            >
              Explore Features
            </Button>
          </div>

          <div ref={featuresSectionRef}>
            <div className="additional-features-section" style={{ marginTop: '40px' }}>
              <h2 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '10px' }}>
                How It Works: Simple, Fast, Affordable Admissions Help
              </h2>
              <p style={{ color: '#000000', fontSize: '16px', textAlign: 'center' }}>
                Our streamlined process delivers personalized, expert advice in just a few steps. Here’s how your question moves from submission to a detailed, polished answer.
              </p>
              <DiagramSection />
            </div>
          </div>
          
          <div className="ui-example-section" style={{ marginTop: "40px" }}>
            <Row>
              <Col style={{ padding: "20px" }}>
                <h4 style={{ fontWeight: "bold" }}>Get the Answers That Matter</h4>
                <p style={{ color: "#000000", fontSize: "14px" }}>
                Whether it’s about perfecting your personal statement, standing out in a competitive applicant pool, or understanding financial aid, ask the questions that matter most to you. We’ll deliver personalized advice to help you stand out and succeed.
                </p>
              </Col>
              <Col style={{ padding: "20px" }}>
                <div style={{
                  width: "100%",
                  height: "250px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "10px",
                  overflow: "hidden",
                  position: "relative",
                  boxShadow: "1 4px 15px rgba(0, 0, 0, 0.5)"
                }}>
                </div>
              </Col>
            </Row>
          </div>

          <div className="additional-components-section" style={{ marginTop: "40px" }}>
            <Row>
              <Col style={{ padding: "20px" }}>
                <h4 style={{ fontWeight: "bold" }}>Real Experts, Real Answers</h4>
                <div style={{
                  width: "100%",
                  height: "250px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "10px",
                  overflow: "hidden",
                  position: "relative",
                  boxShadow: "1 4px 15px rgba(0, 0, 0, 0.5)"
                }}>
                </div>
                <p className="mt-1" style={{ color: "#000000", fontSize: "14px" }}>Our college admissions experts know what it takes to succeed. With years of experience and insider knowledge, they’ll provide answers that are specific to your situation. You’ll receive clear, actionable advice you can trust.</p>
              </Col>

              <Col style={{ padding: "20px" }}>
                <h4 style={{ fontWeight: "bold" }}>Expert Answers on Your Schedule</h4>
                <div style={{
                  width: "100%",
                  height: "250px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "10px",
                  overflow: "hidden",
                  position: "relative",
                  boxShadow: "1 4px 15px rgba(0, 0, 0, 0.5)"
                }}>
                </div>
                <p className="mt-1" style={{ color: "#000000", fontSize: "14px" }}>Have a question at 2 a.m.? We’ve got you covered. Get fast, expert feedback whenever you need it. No long waits or delays—just reliable advice, when you need it most.</p>
              </Col>
            </Row>
          </div>

        </Col>
      </Row>
    </GuestQuestionPageWrapper>
  );
};

export default GuestQuestionPage;