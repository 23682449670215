import React, { useState } from "react";
import { Button, Card, Row, Col, Container, Accordion } from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import styled from "styled-components";

const PageWrapper = styled.div`
  min-height: 100vh;
  padding: 10px; /* Reduced padding */
`;

const HeroSection = styled.div`
  text-align: center;
  padding: 20px 10px; /* Reduced padding */
  margin-bottom: 20px; /* Reduced margin */
`;

const MainHeading = styled.h1`
  font-size: 1.8rem; /* Reduced font size */
  color: #333;
  font-weight: bold;
  margin-bottom: 15px; /* Reduced margin */
`;

const SubHeading = styled.p`
  font-size: 1.1rem; /* Slightly reduced font size */
  color: #555;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 20px; /* Reduced margin */
`;

const SellingPropositions = styled.div`
  display: flex;
  justify-content: space-around; /* Reduced spacing */
  flex-wrap: wrap;
  margin-bottom: 20px; /* Reduced margin */

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SellingPoint = styled.div`
  text-align: center;
  max-width: 220px; /* Reduced width */
  margin-bottom: 10px; /* Reduced margin */
`;

const SellingIcon = styled.div`
  font-size: 1.8rem; /* Reduced icon size */
  color: #6e8efb;
  margin-bottom: 5px; /* Reduced margin */
`;

const SellingTitle = styled.h3`
  font-size: 1.2rem; /* Reduced font size */
  color: #333;
  margin-bottom: 5px; /* Reduced margin */
`;

const SellingText = styled.p`
  font-size: 0.9rem; /* Reduced font size */
  color: #666;
  margin-bottom: 0; /* Removed unnecessary margin */
`;

const TokenCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px; /* Reduced gap between token cards */
  margin-bottom: 20px; /* Reduced margin */
`;

const TokenCard = styled(Card)`
  padding: 10px; /* Reduced padding */
  text-align: center;
  border-radius: 8px; /* Reduced radius */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduced shadow */
  width: 200px; /* Reduced width */
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const TokenTitle = styled.h3`
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 5px; /* Reduced margin */
`;

const PriceText = styled.h4`
  font-size: 1.5rem; /* Reduced font size */
  color: #6e8efb;
  margin-bottom: 10px; /* Reduced margin */
`;

const ChooseButton = styled(Button)`
  background-color: #6e8efb;
  border: none;
  padding: 8px 15px; /* Reduced padding */
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #5a7ae2;
    transform: translateY(-2px);
  }
`;

const FAQWrapper = styled.div`
  margin: 20px 0; /* Reduced margin */
  background-color: #ffffff;
  padding: 20px; /* Reduced padding */
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
`;

const FAQHeading = styled.h2`
  text-align: center;
  font-size: 1.5rem; /* Reduced font size */
  color: #333;
  margin-bottom: 15px; /* Reduced margin */
`;

export default function PurchaseTokens() {
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [loading, setLoading] = useState(false);
  
    // Include the price_id from your Stripe setup here
    const tokenPackages = [
      { id: 1, tokens: 1, price: 1.99, price_id: "price_1Q5vZt05p8417GVDaF8TvRFz" }, // 1 token product
      { id: 2, tokens: 5, price: 7.99, price_id: "price_1Q5ulc05p8417GVDF1ZplOxu" }, // 5 tokens product
      { id: 3, tokens: 10, price: 14.99, price_id: "price_1Q5umO05p8417GVDCYnYJFDT" }, // 10 tokens product
    ];
  
    const handlePurchase = async (pkg) => {
        setSelectedPackage(pkg);
      
        if (!pkg) {
          alert("Please select a package to purchase.");
          return;
        }
      
        setLoading(true);
        try {
          const user = JSON.parse(localStorage.getItem("user"));
          const response = await axios.post(
            "https://api.student.youelite.net/api/create-token-checkout-session",
            { bundle: `${pkg.tokens}-token` },
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
          const sessionId = response.data.id;
          const stripe = await loadStripe(
            "pk_live_51PuZ5305p8417GVD63PxJhMln5YoFPARrSRcB7dyPXwhbBG9Er6nCzDmnp7u0A816YSA6c6bc8jpsszvzQKvsKIM00IDKSOH5g"
          );
          stripe.redirectToCheckout({ sessionId });
        } catch (error) {
          console.error("Error purchasing tokens", error);
          alert("Error during checkout");
        } finally {
          setLoading(false);
        }
      };      
      
  
      return (
        <React.Fragment>
          <Header />
          <div className="main main-app p-3 p-lg-4">
            <PageWrapper>
              {/* Hero Section */}
              <HeroSection>
                <MainHeading>Get the Help You Deserve</MainHeading>
                <SubHeading>
                  Why Pay for Expensive Consultants Around the Clock? Get Expert Help When You Need It.
                </SubHeading>
      
                {/* Selling Propositions now inside Hero */}
                <SellingPropositions>
                  <SellingPoint>
                    <SellingIcon>🎓</SellingIcon>
                    <SellingTitle>Expert Guidance</SellingTitle>
                    <SellingText>
                      Get top-notch advice from professionals in the field, helping you stand out in your college applications.
                    </SellingText>
                  </SellingPoint>
                  <SellingPoint>
                    <SellingIcon>🚀</SellingIcon>
                    <SellingTitle>Boost Your Chances</SellingTitle>
                    <SellingText>
                      Unlock more questions, get deeper insights, and improve your chances of acceptance.
                    </SellingText>
                  </SellingPoint>
                  <SellingPoint>
                    <SellingIcon>📈</SellingIcon>
                    <SellingTitle>Personalized Support</SellingTitle>
                    <SellingText>
                      Receive personalized answers tailored to your unique academic and application needs.
                    </SellingText>
                  </SellingPoint>
                </SellingPropositions>
              </HeroSection>
      
              {/* Token Packages */}
              <Container>
                <TokenCardWrapper>
                  {tokenPackages.map((pkg) => (
                    <TokenCard key={pkg.id}>
                      <TokenTitle>{pkg.tokens} Token{pkg.tokens > 1 ? "s" : ""}</TokenTitle>
                      <PriceText>${pkg.price}</PriceText>
                      <ChooseButton
                        onClick={() => handlePurchase(pkg)} // Pass the package to handlePurchase
                        disabled={loading && selectedPackage?.id === pkg.id}
                      >
                        {loading && selectedPackage?.id === pkg.id ? "Processing..." : "Buy Now"}
                      </ChooseButton>
                    </TokenCard>
                  ))}
                </TokenCardWrapper>
              </Container>
      
              {/* FAQ Section */}
              <Container>
                <FAQWrapper>
                  <FAQHeading>Frequently Asked Questions</FAQHeading>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What are tokens used for?</Accordion.Header>
                      <Accordion.Body>
                        Tokens can be used to unlock premium features such as essay reviews, personalized support, and priority responses on our platform.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>How do I purchase tokens?</Accordion.Header>
                      <Accordion.Body>
                        Simply select a package and click 'Buy Now'. You will be redirected to Stripe for secure checkout. Tokens will be instantly credited to your account.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>What happens after I purchase tokens?</Accordion.Header>
                      <Accordion.Body>
                        After purchasing, tokens will be added to your account and can be used immediately to ask questions or request reviews.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </FAQWrapper>
              </Container>
            </PageWrapper>
            <Footer />
          </div>
        </React.Fragment>
      );
  }