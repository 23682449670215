
// Dashboard
import CollegeDiscovery from "../dashboard/CollegeDiscovery";
import MajorMatchmaker from "../dashboard/MajorMatchmaker";
import CollegeList from "../dashboard/CollegeList";
import FinanceMonitoring from "../dashboard/FinanceMonitoring";
import Cryptocurrency from "../dashboard/Cryptocurrency";
import EssayReview from "../dashboard/EssayReview";
import CollegeComparison from "../dashboard/CollegeComparison";
import ScholarshipFinder from "../dashboard/ScholarshipFinder";
import ApplicationTracker from "../dashboard/ApplicationTracker";
import Ticketing from "../dashboard/Ticketing";
import CostCalculator from "../dashboard/CostCalculator";
import AIChat from "../dashboard/ChatBot";
import Dashboard from "../dashboard/Dashboard";
import ApplicationAnalyzer from "../dashboard/ApplicationAnalyzer";
import Guide from "../dashboard/Guide";
import ArticlePage from "../pages/ArticlePage";
import NotificationsPage from "../pages/NotificationsPage";
import PlanManagement from "../administrationpages/PlanManagement";
import AnalyticsPage from "../administrationpages/AnalyticsPage";
import SupportManagementDashboard from "../administrationpages/SupportManagementDashboard";
import Success from '../components/PaymentSuccess';

//NEW STUDY FEATURE
import CourseList from '../studyhelp/CourseList';
import CourseDetail from '../studyhelp/CourseDetail';
import TestCreation from '../studyhelp/TestCreation';
import TestTaking from '../studyhelp/TestTaking';
import TestResult from '../studyhelp/TestResult';
import ProgressAnalytics from '../studyhelp/ProgressAnalytics';
//end

// Apps
import GalleryMusic from "../apps/GalleryMusic";
import GalleryVideo from "../apps/GalleryVideo";
import Tasks from "../apps/Tasks";
import Contacts from "../apps/Contacts";
import Chat from "../apps/Chat";
import AppCalendar from "../apps/AppCalendar";
import Email from "../apps/Email";
import FileManager from "../apps/FileManager";

// Pages
import Pricing from "../pages/Pricing";
import Faq from "../pages/Faq";
import Profile from "../pages/Profile";
import People from "../pages/People";
import Activity from "../pages/Activity";
import Events from "../pages/Events";
import Settings from "../pages/Settings";
import TourDashboard from "../tours/tourpages/TourDashboard";

// UI Elements
import LayoutColumns from "../docs/LayoutColumns";
import LayoutGrid from "../docs/LayoutGrid";
import LayoutGutters from "../docs/LayoutGutters";
import Accordions from "../docs/Accordions";
import Alerts from "../docs/Alerts";
import Avatars from "../docs/Avatars";
import Badges from "../docs/Badges";
import Breadcrumbs from "../docs/Breadcrumbs";
import Buttons from "../docs/Buttons";
import Cards from "../docs/Cards";
import Carousels from "../docs/Carousels";
import Dropdowns from "../docs/Dropdowns";
import Images from "../docs/Images";
import Listgroup from "../docs/Listgroup";
import Markers from "../docs/Markers";
import Modals from "../docs/Modals";
import NavTabs from "../docs/NavTabs";
import OffCanvas from "../docs/OffCanvas";
import Paginations from "../docs/Paginations";
import Placeholders from "../docs/Placeholders";
import Popovers from "../docs/Popovers";
import Progress from "../docs/Progress";
import Spinners from "../docs/Spinners";
import Toasts from "../docs/Toasts";
import Tooltips from "../docs/Tooltips";
import Tables from "../docs/Tables";
import FormElements from "../docs/FormElements";
import FormSelects from "../docs/FormSelects";
import FormChecksRadios from "../docs/FormChecksRadios";
import FormRange from "../docs/FormRange";
import FormPickers from "../docs/FormPickers";
import FormLayouts from "../docs/FormLayouts";
import UtilBackground from "../docs/UtilBackground";
import UtilBorder from "../docs/UtilBorder";
import UtilColors from "../docs/UtilColors";
import UtilDivider from "../docs/UtilDivider";
import UtilFlex from "../docs/UtilFlex";
import UtilSizing from "../docs/UtilSizing";
import UtilSpacing from "../docs/UtilSpacing";
import UtilOpacity from "../docs/UtilOpacity";
import UtilPosition from "../docs/UtilPosition";
import UtilTypography from "../docs/UtilTypography";
import UtilShadows from "../docs/UtilShadows";
import UtilExtras from "../docs/UtilExtras";
import ApexCharts from "../docs/ApexCharts";
import ChartJs from "../docs/ChartJs";
import MapLeaflet from "../docs/MapLeaflet";
import MapVector from "../docs/MapVector";
import IconRemix from "../docs/IconRemix";
import IconFeather from "../docs/IconFeather";
import EventBackup from "../dashboard/EventManagement copy";

//Socials

import SocialHome from "../socials/SocialHome";
import OtherProfiles from '../pages/OtherProfiles';
import PostDetailsPage from "../socials/PostDetailsPage";
import StudyBuddyMatchPage from "../socials/StudyBuddyMatchPage";
import StudyGroupDetailsPage from "../socials/StudyGroupDetailsPage";
import JoinStudyGroupPage from "../socials/JoinStudyGroupPage";



//support and managing
import ArticlesManager from "../supportpages/ArticlesManager";
import SectionsManager from "../supportpages/SectionsManager";
import SubsectionsManager from "../supportpages/SubsectionsManager";
import TagsManager from "../supportpages/TagsManager";
import SupportDashboard from "../supportpages/SupportDashboard";
import TipManager from "../supportpages/TipManager";


//QUESTION FEATURE
import QuestionPage from "../pages/QuestionPage";
import PurchaseTokens from "../pages/PurchaseTokens";
import AdminQuestions from "../pages/AdminQuestions";


const protectedRoutes = [
  { path: "dashboard/finance", component: FinanceMonitoring, authorizedUserTypes: [80,90,100] },
  { path: "questions-management", component: AdminQuestions, authorizedUserTypes: [80,90,100] },
  { path: "tour-1", component: TourDashboard, authorizedUserTypes: [1,2] },
  { path: "cost", component: CostCalculator, authorizedUserTypes: [1, 2] },
  { path: "questions", component: QuestionPage, authorizedUserTypes: [1, 2] },
  { path: "purchase-tokens", component: PurchaseTokens, authorizedUserTypes: [1, 2] },
  { path: "analyze", component: ApplicationAnalyzer, authorizedUserTypes: [1, 2] },
  { path: "notifications", component: NotificationsPage, authorizedUserTypes: [1, 2,80,90,100] },
  { path: "course", component: Guide, authorizedUserTypes: [1, 2] },
  { path: "course/article/:id", component: ArticlePage, authorizedUserTypes: [1, 2,80,90,100] },
  { path: "", component: Dashboard, authorizedUserTypes: [1, 2,70,80,90,100] },
  { path: "support", component: Ticketing, authorizedUserTypes: [1, 2] },
  { path: "chat", component: AIChat, authorizedUserTypes: [1, 2] },
  { path: "dashboard/eventbackup", component: EventBackup, authorizedUserTypes: [80,90,100] },
  { path: "discovery", component: CollegeDiscovery, authorizedUserTypes: [1, 2] },
  { path: "major-matchmaker", component: MajorMatchmaker, authorizedUserTypes: [1, 2] },
  { path: "list", component: CollegeList, authorizedUserTypes: [1, 2] },
  { path: "dashboard/crypto", component: Cryptocurrency, authorizedUserTypes: [80,90,100] },
  { path: "essay-review", component: EssayReview, authorizedUserTypes: [1, 2] },
  { path: "college-comparison", component: CollegeComparison, authorizedUserTypes: [1, 2] },
  { path: "scholarships", component: ScholarshipFinder, authorizedUserTypes: [1, 2] },
  { path: "apps/gallery-music", component: GalleryMusic, authorizedUserTypes: [80,90,100] },
  { path: "apps/gallery-video", component: GalleryVideo, authorizedUserTypes: [80,90,100] },
  { path: "apps/tasks", component: Tasks, authorizedUserTypes: [80,90,100] },
  { path: "apps/contacts", component: Contacts, authorizedUserTypes: [80,90,100] },
  { path: "apps/chat", component: Chat, authorizedUserTypes: [80,90,100] },
  { path: "deadlines", component: AppCalendar, authorizedUserTypes: [1, 2] },
  { path: "apps/email", component: Email, authorizedUserTypes: [80,90,100] },
  { path: "apps/file-manager", component: FileManager, authorizedUserTypes: [80,90,100] },
  { path: "/plans", component: Pricing, authorizedUserTypes: [1, 2] },
  { path: "faq", component: Faq, authorizedUserTypes: [1, 2] },
  { path: "my-profile", component: Profile, authorizedUserTypes: [1, 2] },
  { path: "pages/people", component: People, authorizedUserTypes: [1, 2] },
  { path: "pages/activity", component: Activity, authorizedUserTypes: [1, 2] },
  { path: "pages/events", component: Events, authorizedUserTypes: [1, 2] },
  { path: "settings", component: Settings, authorizedUserTypes: [1, 2] },
  { path: "docs/layout/grid", component: LayoutGrid, authorizedUserTypes: [1, 2] },
  { path: "docs/layout/columns", component: LayoutColumns, authorizedUserTypes: [1, 2] },
  { path: "docs/layout/gutters", component: LayoutGutters, authorizedUserTypes: [1, 2] },
  { path: "docs/com/accordions", component: Accordions, authorizedUserTypes: [1, 2] },
  { path: "docs/com/alerts", component: Alerts, authorizedUserTypes: [1, 2] },
  { path: "docs/com/avatars", component: Avatars, authorizedUserTypes: [1, 2] },
  { path: "docs/com/badge", component: Badges, authorizedUserTypes: [1, 2] },
  { path: "docs/com/breadcrumbs", component: Breadcrumbs, authorizedUserTypes: [1, 2] },
  { path: "docs/com/buttons", component: Buttons, authorizedUserTypes: [1, 2] },
  { path: "docs/com/cards", component: Cards, authorizedUserTypes: [1, 2] },
  { path: "docs/com/carousel", component: Carousels, authorizedUserTypes: [1, 2] },
  { path: "docs/com/dropdown", component: Dropdowns, authorizedUserTypes: [1, 2] },
  { path: "docs/com/images", component: Images, authorizedUserTypes: [1, 2] },
  { path: "docs/com/listgroup", component: Listgroup, authorizedUserTypes: [1, 2] },
  { path: "docs/com/markers", component: Markers, authorizedUserTypes: [1, 2] },
  { path: "docs/com/modal", component: Modals, authorizedUserTypes: [1, 2] },
  { path: "docs/com/navtabs", component: NavTabs, authorizedUserTypes: [1, 2] },
  { path: "docs/com/offcanvas", component: OffCanvas, authorizedUserTypes: [1, 2] },
  { path: "docs/com/pagination", component: Paginations, authorizedUserTypes: [1, 2] },
  { path: "docs/com/placeholders", component: Placeholders, authorizedUserTypes: [1, 2] },
  { path: "docs/com/popovers", component: Popovers, authorizedUserTypes: [1, 2] },
  { path: "docs/com/progress", component: Progress, authorizedUserTypes: [1, 2] },
  { path: "docs/com/spinners", component: Spinners, authorizedUserTypes: [1, 2] },
  { path: "docs/com/toasts", component: Toasts, authorizedUserTypes: [1, 2] },
  { path: "docs/com/tooltips", component: Tooltips, authorizedUserTypes: [1, 2] },
  { path: "docs/com/tables", component: Tables, authorizedUserTypes: [1, 2] },
  { path: "docs/form/elements", component: FormElements, authorizedUserTypes: [1, 2] },
  { path: "docs/form/selects", component: FormSelects, authorizedUserTypes: [1, 2] },
  { path: "docs/form/checksradios", component: FormChecksRadios, authorizedUserTypes: [1, 2] },
  { path: "docs/form/range", component: FormRange, authorizedUserTypes: [1, 2] },
  { path: "docs/form/pickers", component: FormPickers, authorizedUserTypes: [1, 2] },
  { path: "docs/form/layouts", component: FormLayouts, authorizedUserTypes: [1, 2] },
  { path: "docs/chart/apex", component: ApexCharts, authorizedUserTypes: [1, 2] },
  { path: "docs/chart/chartjs", component: ChartJs, authorizedUserTypes: [1, 2] },
  { path: "docs/map/leaflet", component: MapLeaflet, authorizedUserTypes: [1, 2] },
  { path: "docs/map/vector", component: MapVector, authorizedUserTypes: [1, 2] },
  { path: "docs/icon/remix", component: IconRemix, authorizedUserTypes: [1, 2] },
  { path: "docs/icon/feather", component: IconFeather, authorizedUserTypes: [1, 2] },
  { path: "docs/util/background", component: UtilBackground, authorizedUserTypes: [1, 2] },
  { path: "docs/util/border", component: UtilBorder, authorizedUserTypes: [1, 2] },
  { path: "docs/util/colors", component: UtilColors, authorizedUserTypes: [1, 2] },
  { path: "docs/util/divider", component: UtilDivider, authorizedUserTypes: [1, 2] },
  { path: "docs/util/flex", component: UtilFlex, authorizedUserTypes: [1, 2] },
  { path: "docs/util/sizing", component: UtilSizing, authorizedUserTypes: [1, 2] },
  { path: "docs/util/spacing", component: UtilSpacing, authorizedUserTypes: [1, 2] },
  { path: "docs/util/opacity", component: UtilOpacity, authorizedUserTypes: [1, 2] },
  { path: "docs/util/position", component: UtilPosition, authorizedUserTypes: [1, 2] },
  { path: "docs/util/typography", component: UtilTypography, authorizedUserTypes: [1, 2] },
  { path: "docs/util/shadows", component: UtilShadows, authorizedUserTypes: [1, 2] },
  { path: "/success", component: Success, authorizedUserTypes: [1, 2] },
  { path: "social", component: SocialHome, authorizedUserTypes: [100] },
  { path: "docs/util/extras", component: UtilExtras, authorizedUserTypes: [1, 2] },
  { path: "user/:userId", component: OtherProfiles, authorizedUserTypes: [1, 2] },
  { path: "applications", component: ApplicationTracker, authorizedUserTypes: [1, 2] },
  { path: "posts/:id", component: PostDetailsPage, authorizedUserTypes: [1, 2,80,90,100] },
  { path: "study-buddy", component: StudyBuddyMatchPage, authorizedUserTypes: [1, 2] },
  { path: "study-groups/:id", component: StudyGroupDetailsPage, authorizedUserTypes: [1, 2] },
  { path: "group-join", component: JoinStudyGroupPage, authorizedUserTypes: [1, 2] },
  { path: "sections", component: SectionsManager, authorizedUserTypes: [1, 2,80,90,100] },
  { path: "sections/:sectionId/subsections", component: SubsectionsManager, authorizedUserTypes: [1, 2,80,90,100] },
  { path: "subsections/:subsectionId/articles", component: ArticlesManager, authorizedUserTypes: [1, 2,80,90,100] },
  { path: "tags", component: TagsManager, authorizedUserTypes: [1, 2,80,90,100] },
  { path: "supp-dashboard", component: SupportDashboard, authorizedUserTypes: [80,100] },
  { path: "plan-management", component: PlanManagement, authorizedUserTypes: [100] },
  { path: "support-management", component: SupportManagementDashboard, authorizedUserTypes: [80,100] },
  { path: "tip-management", component: TipManager, authorizedUserTypes: [80,90,100] },
  { path: "analytics", component: AnalyticsPage, authorizedUserTypes: [90,100] },


  //new feature testing
  { path: "courses", component: CourseList, authorizedUserTypes: [1,2,80,90,100] },
  { path: "courses/:id", component: CourseDetail, authorizedUserTypes: [1,2,80,90,100] },
  { path: "courses/:id/test", component: TestCreation, authorizedUserTypes: [1,2,80,90,100] },
  { path: "test/:testId", component: TestTaking, authorizedUserTypes: [1,2,80,90,100] },
  { path: "test/:testId/result", component: TestResult, authorizedUserTypes: [1,2,80,90,100] },
  { path: "progress", component: ProgressAnalytics, authorizedUserTypes: [1,2,80,90,100] },
  
];

export default protectedRoutes;