import React from "react";
import Forbidden from "../pages/Forbidden";
import InternalServerError from "../pages/InternalServerError";
import LockScreen from "../pages/LockScreen";
import NotFound from "../pages/NotFound";
import ServiceUnavailable from "../pages/ServiceUnavailable";
import Signin from "../pages/Signin";
import Signin2 from "../pages/Signin2";
import Signup from "../pages/Signup";
import Signup2 from "../pages/Signup2";
import College_ivy from "../landingpages/college_ivy";
import Commonapp from "../landingpages/commonapp";
import Collegeboard_ap from "../landingpages/collegeboard_ap";
import College_ap_public from "../landingpages/college_ap_public";
import College_no_ads from "../landingpages/college_no_ads";
import VerifyAccount from "../pages/VerifyAccount";
import ProfileSetup from '../pages/ProfileSetup';
import AcademicInterests from '../pages/AcademicInterests';
import AcademicBackground from '../pages/AcademicBackground';
import CollegePreferences from '../pages/CollegePreferences';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import AddSATSignup from '../pages/AddSATSignup';
import EuBlocked from '../pages/EuBlocked';
import Cancel from '../pages/PaymentCancel';
import GuestQuestion from '../pages/GuestQuestionPage';
import GuestPaymentSuccess from '../components/QuestionPaymentSuccess';

const publicRoutes = [
  { path: "signin", element: <Signin /> },
  { path: "guest-question/success", element: <GuestPaymentSuccess /> },
  { path: "pages/signin2", element: <Signin2 /> },
  { path: "signup", element: <Signup /> },
  { path: "signup2", element: <Signup2 /> },
  { path: "la-c-ivy", element: <College_ivy /> },
  { path: "la-c-ap-pub", element: <College_ap_public /> },
  { path: "la-c-nads", element: <College_no_ads /> },
  { path: "la-commapp", element: <Commonapp /> },
  { path: "la-cb-ap", element: <Collegeboard_ap /> },
  { path: "verify", element: <VerifyAccount /> },
  { path: "pages/lock", element: <LockScreen /> },
  { path: "pages/error-404", element: <NotFound /> },
  { path: "pages/error-500", element: <InternalServerError /> },
  { path: "pages/error-503", element: <ServiceUnavailable /> },
  { path: "unauthorized", element: <Forbidden /> },
  { path: "profile-setup", element: <ProfileSetup /> },
  { path: "academic-interests", element: <AcademicInterests /> },
  { path: "academic-background", element: <AcademicBackground /> },
  { path: "college-preferences", element: <CollegePreferences /> },
  { path: "forgot-password", element: <ForgotPassword /> },
  { path: "onboarding-sat", element: <AddSATSignup /> },
  { path: "reset-password", element: <ResetPassword /> },
  { path: "cancel", element: <Cancel /> },
  { path: "guest-question", element: <GuestQuestion /> },
  { path: "eu", element: <EuBlocked /> }
];

export default publicRoutes;